html, body, #root
{
	/* we don't want any of these to scroll */
	overflow: hidden;
	height: 100%;
}

.ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

/* Search Result Scrolling and sizing*/
.ui.search > .results {
  overflow-y: auto;
  max-height: 80vh;
}

/* Google login customization */
button.googleLogin {
  height: 35px;
  justify-content: center !important;
  align-items:center !important;
}

button.googleLogin > div {
  padding: 5px !important;
  margin-right: 5px;
}

button.googleLogin > span {
  padding: 5px;
  margin-right: 5px;
}

#heatmap {
  width: 70vw;
  height: calc(60vh - 30px);
}

.dc-chart text.pie-slice.pie-label {
  fill: black;
  font-weight: bold;
}

.dc-chart g.row text {
  fill: black;
  font-weight: bold;
}

div.mobile div#surveyContainer {
  margin: 0 0 0 0!important;
}

div.app {
  display: inline;
}

div.segment.footer {
  padding: '0em 0em';
  flex-shrink: 0; /* don't allow flexbox to shrink it */
  border-radius: 0; /* clear semantic-ui style */
  margin: 0; /* clear semantic-ui style */
  padding: 3px 0px 3px 0px;
}

div.footer.footer-menu {
  display: inline;
}


.ui.card.current {
  background-color: #e9fbc5!important;
}

.ui.card.current:hover {
  background-color: #e9fbc5!important;
}